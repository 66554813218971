var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"col":"12"}},[_c('CCard',{staticClass:"zq--wizard-card"},[_c('CCardHeader',{on:{"click":_vm.collapse}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('strong',{staticClass:"title"},[_vm._v(_vm._s(_vm.texts.previewPage.rewards.title))]),_c('CLink',{staticClass:"card-header-action btn-minimize"},[_c('ClCardArrow',{attrs:{"is-collapsed":_vm.rewardsIsCollapsed}})],1)],1)]),_c('CCollapse',{attrs:{"show":_vm.rewardsIsCollapsed,"duration":400}},[_c('CCardBody',[(_vm.viewRewardsData.length)?_c('CDataTable',{staticClass:"zq--table",attrs:{"items":_vm.viewRewardsData,"id":"rewardsTablePreview","fields":_vm.fields,"responsive":true,"border":_vm.isDefaultTheme,"hover":_vm.isDefaultTheme,"striped":_vm.isDefaultTheme},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showReward(item)}}},[_vm._v(" "+_vm._s(item.id)+" ")])])]}},{key:"created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.dateFormate(item.created)))])]}},{key:"rewardName",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.name))])]}},{key:"rewardRank",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.rewardRank))])]}},{key:"value",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.rewardValue))])]}},{key:"rewardType",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.rewardTypeId))])]}}],null,false,3294573260)}):(_vm.isCompetition && !_vm.viewRewardsData.length)?_c('div',{staticClass:"link-wrapper"},[_c('span',{staticClass:"link",on:{"click":_vm.goToContestsTab}},[_vm._v(" "+_vm._s(_vm.texts.noRewards)+" ")])]):_c('div',{staticClass:"link-wrapper"},[_c('span',[_vm._v(" No rewards ")])])],1)],1)],1)],1)],1),_c('Modal',{attrs:{"modalShow":_vm.isModal,"messageGeneral":_vm.modalText,"title":_vm.modalTitle,"isSuccessButton":false},on:{"toggle-modal":function($event){_vm.isModal = false},"closeModal":function($event){_vm.isModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }