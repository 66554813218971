<template>
  <div>
    <CRow>
      <CCol col="12">
        <CCard class="zq--wizard-card">
          <CCardHeader @click="collapse">
            <div class="d-flex justify-content-between">
              <strong class="title">{{ texts.previewPage.rewards.title }}</strong>
              <CLink class="card-header-action btn-minimize">
                <ClCardArrow :is-collapsed="rewardsIsCollapsed" />
              </CLink>
            </div>
          </CCardHeader>
          <CCollapse :show="rewardsIsCollapsed" :duration="400">
            <CCardBody>
              <CDataTable
                v-if="viewRewardsData.length"
                :items="viewRewardsData"
                id="rewardsTablePreview"
                :fields="fields"
                class="zq--table"
                :responsive="true"
                :border="isDefaultTheme"
                :hover="isDefaultTheme"
                :striped="isDefaultTheme"
              >
                <template #id="{ item }">
                  <td>
                    <a href="#" @click.prevent="showReward(item)">
                      {{ item.id }}
                    </a>
                  </td>
                </template>
                <template #created="{item}">
                  <td>{{ dateFormate(item.created) }}</td>
                </template>
                <template #rewardName="{item}">
                  <td>{{ item.name }}</td>
                </template>
                <template #rewardRank="{item}">
                  <td>{{ item.rewardRank }}</td>
                </template>
                <template #value="{item}">
                  <td>{{ item.rewardValue }}</td>
                </template>
                <template #rewardType="{item}">
                  <td>{{ item.rewardTypeId }}</td>
                </template>
              </CDataTable>
              <div class="link-wrapper" v-else-if="isCompetition && !viewRewardsData.length">
                <span class="link" @click="goToContestsTab">
                  {{ texts.noRewards }}
                </span>
              </div>
              <div class="link-wrapper" v-else>
                <span>
                  No rewards
                </span>
              </div>
            </CCardBody>
          </CCollapse>
        </CCard>
      </CCol>
    </CRow>
    <Modal
      :modalShow="isModal"
      :messageGeneral="modalText"
      :title="modalTitle"
      :isSuccessButton="false"
      v-on:toggle-modal="isModal = false"
      @closeModal="isModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { dateFormate } from '@/utils/dateFormate';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import { achievementsTexts } from "@/config/pageTexts/achievements.json";
import { cloneDeep } from "lodash";
import Modal from '@/shared/UI/Modal.vue';

export default {
  name: 'ViewRewards',
  components: {
    Modal,
    ClCardArrow
  },
  props: {
    rewardsData: {
      type: Object,
      default: () => {},
    },
    entityId: {
      type: String,
      default: '',
    },
    isCompetition: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModal: false,
      modalTitle: achievementsTexts.previewPage.rewards.modalTitle,
      modalText: '',
      texts: {
        ...achievementsTexts
      },
      rewardsIsCollapsed: false,
      viewRewardsData: [],
      fields: ['id', 'rewardName', 'rewardRank', 'value', 'rewardType', 'created']
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default'
    }
  },
  methods: {
    ...mapActions('rewards', ['handleGetRewardsByQuery']),
    goToContestsTab() {
      this.$emit('goToContestsTab', 1);
      this.basicIsCollapsed = false;
    },
    async collapse() {
      this.rewardsIsCollapsed = !this.rewardsIsCollapsed;
      if (this.rewardsData && Object.keys(this.rewardsData).length) {
        this.viewRewardsData = cloneDeep(this.rewardsData);
      }
      if (this.rewardsIsCollapsed && Object.keys(this.viewRewardsData).length === 0) {
        let data = await this.handleGetRewardsByQuery(
          {
            queryRequest: {
              should: [{
                queryField: 'entityId',
                queryValues: [this.entityId]
              }],
              shouldMatch: 1
            }
          }
        )
        this.viewRewardsData = cloneDeep(data).sort(this.compareByRewardRank);
      }
      this.viewRewardsData.forEach(reward => {
        if (reward.rewardType && reward.rewardType.id) {
          reward.rewardTypeId = reward.rewardType.id;
          reward.rewardType = reward.rewardType.key;
        }
      })
    },
    compareByRewardRank(a, b) {
      const rankA = this.parseRewardRank(a.rewardRank);
      const rankB = this.parseRewardRank(b.rewardRank);

      if (rankA < rankB) {
        return -1;
      }
      if (rankA > rankB) {
        return 1;
      }
      return 0;
    },
    parseRewardRank(rank) {
      if (rank.includes('-')) {
        return parseInt(rank.split('-')[0]);
      }

      return parseInt(rank);
    },
    dateFormate(val) {
      return dateFormate(val)
    },
    showReward(reward) {
      console.log(reward)
      this.modalText = `
        <p><b>Id:</b> <a href="#/rewards/detail/${reward.id}">${reward.id}</a></p>
        <p><b>Name:</b> ${reward.name}</p>
        <p><b>Reward Type:</b> ${reward.rewardType}</p>
        <p><b>Reward Type Id:</b> <a href="#/settings/reward-types/detail/${reward.rewardTypeId}">${reward.rewardTypeId}</a></p>
        <p><b>Reward Rank:</b> ${reward.rewardRank}</p>
        <p><b>Value:</b> ${reward.rewardValue}</p>
        <p><b>Constraints:</b> ${reward.constraints}</p>
      `;
      this.isModal = true;
    },
  },
  watch: {
    formData(val) {
      this.data = val
    }
  }
}
</script>

<style lang="scss">
#rewardsTablePreview {
  box-shadow: none;
  &.zq--table > div {
    min-height: auto;
  }
}
</style>